import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "product" ]

  connect() {
    if(this.productTargets.length == 0) {
      this.element.style.display = 'none'
    }
  }

}