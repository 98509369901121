import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label" ]

  sendRequest = debounce(function() {
    if(this.currentNumber > 0) {
      $.post(`/app/products/${this.productId}/add_to_cart`, {quantity: this.currentNumber}, function(data) {
        // Update
        this.updateCartBadge(data.cart_count)
        this.updateShoppingCart(data.cart_count, data.order_total)
      }.bind(this))
    } else {
      $.post(`/app/products/${this.productId}/remove_from_cart`, function(data) {
        // Update
        this.updateCartBadge(data.cart_count)
        this.updateShoppingCart(data.cart_count, data.order_total)

        // Some jQuery to animate the remove animation
        if($('.shopping-cart-overview').length > 0) {
          $(this.element).parents('.product').slideUp()
        }

        // Remove product from cart
        this.labelTarget.innerText = 0
        this.element.classList.remove('opened')
        this.element.classList.remove('in-shopping-cart')
      }.bind(this))
    }
  }, 300)

  add(event) {
    event.preventDefault()

    if (this.element.classList.contains('opened') || !this.element.classList.contains('in-shopping-cart')) {
      // Add to number
      let number = this.currentNumber + 1
      this.labelTarget.innerText = number

      // Pulsate and send request
      this.pulse()
      this.sendRequest()
    }

    // Open control
    this.element.classList.add('opened')
  }

  subtract(event) {
    event.preventDefault()

    let number = this.currentNumber - 1
    if(number < 0)
      number = 0
    this.labelTarget.innerText = number

    this.pulse()
    this.sendRequest()
  }

  // VOID function necessary for pulse animation
  VOID = function(a) {
    return undefined
  }

  pulse() {
    this.labelTarget.classList.remove('pulse')
    this.VOID(this.labelTarget.offsetWidth) // Hacky way to reset rendering
    this.labelTarget.classList.add('pulse')
  }

  updateCartBadge(count) {
    if(typeof(webkit) != 'undefined') {
      webkit.messageHandlers.brinks.postMessage({message: "updateCartBadge", value: count})
    }
  }

  updateShoppingCart(count, total) {
    if(document.querySelector('.shopping-cart-summary span')) {
      document.querySelector('.shopping-cart-summary span').innerText = count
      document.querySelector('.shopping-cart-totals span').innerText = total
    }
  }

  get currentNumber() {
    return parseInt(this.labelTarget.innerText)
  }

  get productId() {
    return this.labelTarget.dataset.productId
  }

}
